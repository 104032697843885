import * as React from "react"
import Layout from "../components/layout"
import { useEffect, useState} from "react"

import { Col } from "react-bootstrap"
import Seo from "../components/seo"
import "./support.scss"



const FHBPage = () => {

  useEffect(() => {    
    const script = document.createElement('script');
    script.src = 'https://www.booking.com/affiliate/prelanding_sdk';
    script.async = true;
    document.body.appendChild(script);        

    return () => {      
      document.body.removeChild(script);  
    }
  }, []);


  useEffect(() => {      
    
    const onPageLoad = () =>{
      const script1 = document.createElement('script');
      script1.defer = true;
      script1.innerHTML = `(function () {
        var BookingAffiliateWidget = new Booking.AffiliateWidget({
        "iframeSettings": {
        "selector": "bookingAffiliateWidget_b7482b54-efa7-4f3c-8b85-7bb9bf8247a5",
        "responsive": true
        },
        "widgetSettings": {}
        });
          })();`  
  
      document.body.appendChild(script1);
  
      return () => {           
        document.body.removeChild(script1);
      }
    }
    
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
    
  }, []);

  return (
    <Layout>
      <Seo title="Flight & Hotel Booking" />
      <div className="contentalign">
        <div className="justify-content-ms-center row">        
  
            <div id="bookingAffiliateWidget_b7482b54-efa7-4f3c-8b85-7bb9bf8247a5">&nbsp;</div>
                        
                    
        </div>
      </div>
    </Layout>)
}

export default FHBPage

